import { defineStore } from "pinia";

export const usePaymentStore = defineStore("payment", {
  state: () => ({
    opened: false,
    loading: false,
  }),
  actions: {
    openCheckout() {
      this.opened = true;
    },
  },
});
